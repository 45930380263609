import React from 'react';
import {MessageService, TwoMessage} from 'two-app-ui';
import {config} from '../../config/config';
import {Subscription} from 'rxjs';

interface State {
  title: string;
}

class CenterPart extends React.Component<{}, State> {
  subscription: Subscription = new Subscription();
  constructor(props: {}) {
    super(props);
    this.state = {
      title: '',
    };
  }

  async componentDidMount() {
    this.subscription = MessageService.getMessage().subscribe(async message => {
      if (message === config().messages.schedulerViewed) {
        this.setState({
          title: 'Schedule',
        });
      } else {
        const castedMessage = message as TwoMessage;
        if (
          castedMessage?.name === config().messages.jobViewed ||
          castedMessage?.name === config().messages.dcmViewed ||
          castedMessage?.name === config().messages.originalRequestViewed
        ) {
          this.setState({
            title: castedMessage.value as string,
          });
        }
      }
    });
  }

  render() {
    const {title} = this.state;
    return (
      <div className="app-toolbar-part app-toolbar-center-part p-text-center">
        <strong className="">{title}</strong>
      </div>
    );
  }
}

export default CenterPart;
