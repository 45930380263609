import React, {Component} from 'react';
import Made2FitAppContext from './context/Made2FitAppContext';

import {AuthService, MessageService, ObaFrame, ToastService, TwoToolbar, UsersService} from 'two-app-ui';
import JobsService from './services/JobsService';
import AppointmentsService from './services/AppointmentsService';
import JobDocumentsService from './services/JobDocumentsService';
import ProductDefinitionsService from './services/ProductDefinitionsService';
import TleService from './services/TleService';
import CompaniesService from './services/CompaniesService';
import ContactsService from './services/ContactsService';
import LeftPart from './components/TopFrame/LeftPart';
import CenterPart from './components/TopFrame/CenterPart';
import RightPart from './components/TopFrame/RightPart';
import {Route, Switch} from 'react-router-dom';
import OriginalRequest from './components/JobDocument/OriginalRequest/OriginalRequest';
import Dcm from './components/JobDocument/Dcm/Dcm';
import JobDetail from './components/Job/JobDetail';
import Scheduler from './components/Scheduler/Scheduler';
import {AuthenticatedTwoUser, Company, PaCompany, User} from 'two-core';
import config from './config/config';
import {Subscription} from 'rxjs';

const authService = new AuthService();
const toastService = new ToastService();
const usersService = new UsersService(authService);
const jobsService = new JobsService(authService);
const appointmentsService = new AppointmentsService(authService);
const jobDocumentsService = new JobDocumentsService(authService);
const productDefinitionsService = new ProductDefinitionsService(authService);
const tleService = new TleService(authService);
const companiesService = new CompaniesService(authService);
const contactsService = new ContactsService(authService);

interface State {
  companies: PaCompany[];
}
export class App extends Component<{}, State> {
  subscription: Subscription = new Subscription();
  constructor(props: {}) {
    super(props);
    this.state = {
      companies: [],
    };

    this.loadCompanies = this.loadCompanies.bind(this);
    this.onCompanyChanged = this.onCompanyChanged.bind(this);
    this.initCompanies = this.initCompanies.bind(this);
  }

  componentDidMount() {
    this.subscription = MessageService.getMessage().subscribe(async message => {
      if (message === config().messages.topSelectionChanged) {
        this.onCompanyChanged();
      }
    });
  }

  initCompanies = async () => {
    try {
      if (await authService.isSessionValid()) {
        const user: AuthenticatedTwoUser | undefined = usersService.getCurrentTwoUser();
        if (user) {
          await this.loadCompanies();
        }
      }
    } catch (error) {
      this.setState({
        companies: [],
      });
      console.error(error);
    }
  };

  async loadCompanies() {
    const filters = [
      JSON.stringify({
        field: 'company_contact.in_role',
        value: 'fitter',
      }),
    ];
    const orderBys = [JSON.stringify({field: 'name', direction: 'ASC'})];
    companiesService
      ?.getCompanies({
        aggregate: ['company_contacts'],
        orderBys: orderBys,
        filters: filters,
      })
      .then(companiesApiListResponse => {
        const companies = (companiesApiListResponse.records as PaCompany[]) ?? [];

        if (!companies.length) {
          localStorage.removeItem(config().ls_keys.current_company);
          return;
        }

        const currentCompanyId = localStorage.getItem(config().ls_keys.current_company);
        let selectedCompany = companies.find(company => currentCompanyId === company.id);
        if (!currentCompanyId || !selectedCompany) {
          selectedCompany = companies[0];
          localStorage.setItem(config().ls_keys.current_company, selectedCompany.id);
          localStorage.setItem(config().ls_keys.current_role, selectedCompany.my_role);
        }

        this.setState({
          companies: companies,
        });

        MessageService.sendMessage(config().messages.topSelectionDataLoaded);
      })
      .catch(error => {
        localStorage.removeItem(config().ls_keys.current_company);
        throw new Error('Error ' + error);
      });
  }

  onCompanyChanged() {
    const newCompanyId = localStorage.getItem(config().ls_keys.current_company);
    const newCompany = this.state.companies.find(company => company.id === newCompanyId);

    if (newCompany) {
      localStorage.setItem(config().ls_keys.current_role, newCompany.my_role);
    }
  }

  renderTopFrame = (companies: PaCompany[]) => {
    return <TwoToolbar left={<LeftPart />} center={<CenterPart />} right={<RightPart companies={companies} />} />;
  };

  render() {
    const {companies} = this.state;
    const value = {
      authService: authService,
      usersService: usersService,
      toastService: toastService,
      jobsService: jobsService,
      appointmentsService: appointmentsService,
      jobDocumentsService: jobDocumentsService,
      productDefinitionsService: productDefinitionsService,
      tleService: tleService,
      companiesService: companiesService,
      contactsService: contactsService,
    };

    return (
      <Made2FitAppContext.Provider value={value}>
        <ObaFrame contextValues={value} topFrame={this.renderTopFrame(companies)} onSuccessfulInit={this.initCompanies}>
          <Switch>
            <Route path="/job/:jobId/original-request/:jobDocumentId">
              <OriginalRequest />
            </Route>
            <Route path="/job/:jobId/dcm/:jobDocumentId/:tab">
              <Dcm />
            </Route>
            <Route path="/job/:jobId/dcm/:jobDocumentId">
              <Dcm />
            </Route>
            <Route path="/job/:id">
              <JobDetail />
            </Route>
            <Route path="/scheduler">
              <Scheduler />
            </Route>
            <Route path="/">
              <Scheduler />
            </Route>
            <Route path="/:any">
              <Scheduler />
            </Route>
          </Switch>
        </ObaFrame>
      </Made2FitAppContext.Provider>
    );
  }
}

export default App;
