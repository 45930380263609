import React from 'react';
import {NavLink, RouteComponentProps, withRouter} from 'react-router-dom';
import {Subscription} from 'rxjs';
import {faChevronLeft} from '@fortawesome/pro-light-svg-icons';
import {AppContext, AuthService} from 'two-app-ui';
import {Button} from 'primereact/button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface State {
  backAction: string;
  backTitle: string;
  path: string;
  isLogoutAction: boolean;
}

class LeftPart extends React.Component<RouteComponentProps<{}>, State> {
  static contextType = AppContext;
  subscription: Subscription = new Subscription();
  authService?: AuthService;

  constructor(props: RouteComponentProps<{}>) {
    super(props);
    this.state = {
      backAction: '/',
      backTitle: '',
      path: '',
      isLogoutAction: false,
    };
  }

  componentDidMount() {
    this.authService = this.context.authService;

    this.logout = this.logout.bind(this);

    this.setAction();
  }

  componentDidUpdate(prevProps: RouteComponentProps<{}>) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setAction();
    }
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  logout() {
    this.authService!.signOut();
    window.location.href = 'login';
  }

  setAction() {
    const prevBackAction = this.state.backAction;
    const location = this.props.location;
    const path = location?.pathname;

    let backTitle = '';
    let backAction = '/';
    let isLogoutAction = false;

    if (path === '/scheduler' || path === '/') {
      backTitle = 'Logout';
      backAction = '/login';
      isLogoutAction = true;
    } else if (path?.includes('/job')) {
      if (path?.includes('/dcm')) {
        const jobId = path.match(/\/job\/(.+)\/dcm/i)?.[1];
        backTitle = 'Cancel';
        backAction = `/job/${jobId}`;
      } else if (path?.includes('/original-request')) {
        const jobId = path.match(/\/job\/(.+)\/original-request/i)?.[1];
        backTitle = 'Back';
        backAction = `/job/${jobId}`;
      } else {
        backTitle = 'Schedule';
        backAction = '/scheduler';
      }
    } else {
      console.error('Unknown path.');
    }

    if (backTitle !== prevBackAction) {
      this.setState({
        backTitle: backTitle,
        backAction: backAction,
        path: path,
        isLogoutAction: isLogoutAction,
      });
    }
  }

  render() {
    const {isLogoutAction, backAction, backTitle} = this.state;

    if (isLogoutAction) {
      return (
        <div className="app-toolbar-part">
          <Button onClick={this.logout} className="p-button p-button-text p-button-plain p-p-0">
            <small className="p-button-icon">
              <FontAwesomeIcon icon={faChevronLeft} size={'lg'} />
            </small>
            <small className="p-ml-2 p-button-label">{backTitle}</small>
          </Button>
        </div>
      );
    }
    return (
      <div className="app-toolbar-part">
        <NavLink className="p-button p-button-text p-button-plain p-p-0" to={backAction}>
          <small className="p-button-icon">
            <FontAwesomeIcon icon={faChevronLeft} size={'lg'} />
          </small>
          <small className="p-ml-2 p-button-label">{backTitle}</small>
        </NavLink>
      </div>
    );
  }
}

export default withRouter(LeftPart);
